import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import InsightsIcon from '@mui/icons-material/Insights';
import LanguageIcon from '@mui/icons-material/Language';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import QuizIcon from '@mui/icons-material/Quiz';
// import SchoolIcon from '@mui/icons-material/School';
import SubtitlesIcon from '@mui/icons-material/Subtitles';

export const TOOLS = [
  // {
  //   title: 'Recipe Builder',
  //   link: 'tool/builder/recipe-builder',
  //   description:
  //     'Combine all of the best tools into one creation. Generate a lesson plan, PowerPoint, and more - all at once.',
  //   icon: <SchoolIcon />,
  // },
  {
    title: 'Quiz Builder',
    link: 'tool/custom/quiz-builder',
    description:
      'Create a quiz in minutes with the help of Copilot. This tool is great for creating formative assessments.',
    icon: <QuizIcon />,
  },
  {
    title: 'Lesson Planner',
    link: 'tool/generator/lessonplannerv2',
    description:
      'Save time and energy by creating a detailed lesson plan with the help of Copilot. Never stare at a blank paper again.',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Educational Handout',
    link: 'tool/generator/educational-handout',
    description:
      'Create a detailed and structured handout to prepare yourself or students for a lesson. This tool goes great paired with the Lesson Planner!',
    icon: <SubtitlesIcon />,
  },
  {
    title: 'PowerPoint Generator',
    link: 'tool/builder/powerpoints',
    description:
      'Generate a 15 slide, educational PowerPoint on any topic. Simply provide a few details and let Copilot do the rest!',
    icon: <CoPresentIcon />,
  },
  {
    title: 'Context Builder',
    link: 'tool/generator/context-builder',
    description:
      // eslint-disable-next-line
      "Scaffold your lesson with a context builder. This tool will ensure your students don't miss a beat when building their understanding about a topic.",
    icon: <ContentPasteIcon />,
  },
  {
    title: 'Idea Generator',
    link: 'tool/generator/idea-generator',
    description:
      'Generate a list of ideas for a lesson, project, or anything else you can think of. This tool is great for brainstorming activity or lesson ideas!',
    icon: <InsightsIcon />,
  },
  {
    title: 'AI Freestyle',
    link: 'tool/generator/freestyle',
    description: 'Tell Copilot what to do, without any restrictions, formatting, or barriers!',
    icon: <AccountTreeIcon />,
  },
  {
    title: 'Real World Benefits',
    link: 'tool/generator/real-life-benefits',
    description:
      'Generate a list of real world benefits for a topic. This tool is great for helping students understand the importance of a topic.',
    icon: <LanguageIcon />,
  },
  {
    title: 'Project Generator',
    link: 'tool/generator/research-project-generator',
    description:
      'Generate a project for any unit or topic. This tool is perfect for coming up with fun, engaging, and educational projects for students.',
    icon: <FeaturedPlayListIcon />,
  },
  {
    title: 'Writing Prompts',
    link: 'tool/generator/writing-prompts',
    description:
      'Invoke critical thinking and creativity with an AI generated writing prompt. This tool is great for helping students conceptualize new topics.',
    icon: <FormatColorTextIcon />,
  },
  {
    title: 'Student Reports',
    link: 'tool/generator/student-reports',
    description:
      'Generate a report for a student. This tool is great for helping students understand their strengths and weaknesses.',
    icon: <ContactPageIcon />,
  },
  {
    title: 'Parent Emails',
    link: 'tool/generator/parent-emails',
    description:
      // eslint-disable-next-line
      "Generate a parent email for a student. This tool is great for keeping parents informed about their child's progress.",
    icon: <MarkEmailReadIcon />,
  },
];
