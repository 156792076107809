import { getAuth } from 'firebase/auth';
import { collection, getDocs, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import { Loader } from '../../components/Loader';

import { db } from '../../firebase.config';
import { getPlans } from '../../store/reducers/plansSlice';
import { payPlan } from '../../utils/payPlan';
// Stripe / Auth Services
import PricingCard from './PricingCard';
import './pricing.css';

const PricingPage = () => {
  const [subscription, setSubscription] = useState(null);
  const { plans } = useSelector((state) => state.plansSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!plans) {
      dispatch(getPlans());
    }
  }, []);

  useEffect(() => {
    getSubscription();
  }, [plans]);

  const getSubscription = async () => {
    const auth = getAuth();
    const dbQuery = query(collection(db, 'users', auth?.currentUser.uid, 'subscriptions'));
    const subscriptionsSnapshot = await getDocs(dbQuery);
    subscriptionsSnapshot.forEach((snapshot) => {
      const subscription = snapshot.data();
      if (subscription.status === 'active') {
        setSubscription(subscription);
      }
    });
  };

  const checkOut = async (priceId) => {
    await payPlan(priceId);
  };

  return (
    <>
      <div className="header">
        <div className="main-header">
          Get <span style={{ color: '#7d5ff5' }}>Unlimited</span> Access to Copilot 🚀
        </div>
        <div className="sub-header">
          <span style={{ color: '#7d5ff5', fontWeight: '700' }}>Turbocharge </span>
          your productivity inside and outside of the classroom!
        </div>
      </div>
      <div className="pricing-container">
        <Loader loading={!plans} />
        {plans &&
          plans.map((price, index) => {
            return (
              <PricingCard
                price={price}
                key={price.id}
                checkOut={checkOut}
                subscription={subscription}
                isPurpleOutline={index === 1}
              />
            );
          })}
      </div>
    </>
  );
};
export default PricingPage;
