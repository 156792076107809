import { getAuth } from '@firebase/auth';
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
// import { decode } from 'html-entities';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import RiseLoader from 'react-spinners/RiseLoader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { db } from '../../../../firebase.config';
import { nl2br } from '../../../../utils/nl2br';
import '../multiform.css';

const UnitPlanner = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [unitUpdated, setUnitUpdated] = useState(false);
  const [unitID, setUnitID] = useState('');
  const [completion, setCompletion] = useState({
    unitName: '',
    unitLength: '',
    time: '',
    gradeLevel: '',
    id: '',
  });

  const [unitData, setUnitData] = useState([]);

  const unitName = props.unitName;
  const unitStandards = props.unitStandards;
  const unitDetails = props.unitDetails;
  const unitLength = props.unitLength;

  useEffect(() => {
    console.log('unitName', unitName);
    console.log('unitStandards', unitStandards);
    console.log('unitDetails', unitDetails);
    console.log('unitLength', unitLength);
  }, [unitName, unitStandards, unitDetails, unitLength]);

  // const navigate = useNavigate();

  const auth = getAuth();
  const user = auth.currentUser;

  const completionRef = useRef(null);
  completionRef.current = completion;

  async function getFilteredDataFromDBForUser(collectionName, filter) {
    const docsToAdd = [];
    let dbQuery;
    if (filter === 'All Tools') {
      dbQuery = query(collection(db, collectionName), where('userId', '==', user.uid));
    } else if (Array.isArray(filter)) {
      dbQuery = query(
        collection(db, collectionName),
        where('userId', '==', user.uid),
        where('application', 'in', filter)
      );
    }

    const querySnapshot = await getDocs(dbQuery);
    querySnapshot.forEach((doc) => {
      docsToAdd.push({
        ...doc.data(),
        id: doc.id,
      });
    });

    docsToAdd.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
  }

  React.useEffect(() => getFilteredDataFromDBForUser('units', 'All Tools'), []);

  async function saveUnitToDB(unitName, data) {
    data = {
      ...data,
      userId: user.uid,
      timestamp: Date.now(),
    };
    const ref = await addDoc(collection(db, unitName), data);
    setUnitID(ref.id);
    return ref;
  }

  async function storeUnit(unitName, unitLength, unitStandards, unitDetails) {
    const dataToSave = {
      unitName,
      unitLength,
      unitStandards,
      unitDetails,
    };

    await saveUnitToDB('units', dataToSave)
      .then((ref) => {
        setCompletion({
          ...dataToSave,
          id: ref.id,
        });

        setUnitID(ref.id);
      })
      .catch((err) => console.log('error', err));
  }

  async function fetchApi(unitName, unitLength, unitStandards, unitDetails) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    notify();

    const raw = JSON.stringify({
      unitName,
      unitLength,
      unitStandards,
      unitDetails,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(`${window.location.origin}/api/v1/completions/unitOverviewCompletion`, requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        console.log('result ===', result.choices[0].message.function_call.arguments);

        // Assuming the response is structured as the "function_call" field in your result example
        const unitStructure = JSON.parse(result.choices[0].message.function_call.arguments);
        const parsedData = unitStructure.days.map((day) => ({
          dayNumber: day.day_number,
          lessonName: day.lessonName,
          overview: nl2br(day.overview),
        }));

        setUnitData(parsedData);
        setUnitUpdated(true);
      })
      .catch((error) => console.log('error', error));
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await fetchApi(unitName, unitLength, unitStandards, unitDetails);
    await storeUnit(unitName, unitLength, unitStandards, unitDetails);
  };

  useEffect(() => {
    if (unitUpdated) {
      const docRef = doc(db, 'units', unitID);

      const updateData = unitData.reduce((acc, item) => {
        acc[`day${item.dayNumber}`] = { lessonName: item.lessonName, overview: item.overview };
        // acc[`title${item.dayNumber}`] = `Title ${item.dayNumber}`; // Assuming titles are generated dynamically
        return acc;
      }, {});

      updateDoc(docRef, updateData).then(() => {
        setIsLoading(false);
        toast.dismiss();
        // navigate(`/unit-planner/${unitID}`);
      });
    }
  }, [unitUpdated]);

  const notify = () =>
    toast('🦄  Now sit back and relax!', {
      position: 'top-right',
      autoClose: unitLength * 4 * 1100,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
      pauseOnFocusLoss: false,
    });

  return (
    <div className="unitpage">
      <form onSubmit={onSubmit}>
        <button className="btn btn-block create" type="submit" disabled={isLoading}>
          {!isLoading && <>Create Unit 🚀</>}
          {isLoading && <RiseLoader color={'white'} loading={isLoading} size={7} />}
        </button>
      </form>
    </div>
  );
};

export default UnitPlanner;
