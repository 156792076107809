import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Drawer, Typography } from '@mui/material';
// Acordian imports
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { IoEyeSharp, IoTimerOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';

import { db } from '../firebase.config';
import VideoList from '../pages/ai-tools/custom/VideoList';
import ContextBuilderButton from '../pages/dashboard/UnitPlanner/API/ContextBuilderAPI';
import EducationalHandoutButton from '../pages/dashboard/UnitPlanner/API/EducationalHandoutAPI';
import EssentialQuestionsButton from '../pages/dashboard/UnitPlanner/API/EssentialQuestionsAPI';
import LessonPlanButton from '../pages/dashboard/UnitPlanner/API/LessonPlanAPI';
import LessonOverviewButton from '../pages/dashboard/UnitPlanner/API/RegenButtons/LessonOverviewButton';
import StudentObjectivesButton from '../pages/dashboard/UnitPlanner/API/StudentObjectivesAPI';
import { nl2br } from '../utils/nl2br';
import './tool/Tool.css';

const Tool = ({ title, overview, day, dayNumber, unitDetails, unitName }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [lessonOverview] = useState('');

  const { unitID } = useParams();

  const overviewState = overview;

  useEffect(() => {
    if (lessonOverview) {
      saveObjectives(dayNumber);
    }
  }, [lessonOverview]);

  const saveObjectives = async (dayNumber) => {
    try {
      const updateDay = async (dayNumber) => {
        const docRef = doc(db, 'units', unitID);
        const docSnap = await getDoc(docRef);
        const day = await docSnap.get(dayNumber);
        const updatedDay = {
          ...day,
          matchFirst: `${JSON.stringify(nl2br(lessonOverview))}`,
        };
        await updateDoc(docRef, { [dayNumber]: updatedDay });
      };

      await updateDay(dayNumber);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Box p={2} role="presentation" className="drawerbox">
          <div className="navcontrols">
            <button
              style={{
                marginLeft: '.7rem',
                padding: '0.5rem 1rem',
                borderRadius: '5px',
                border: 'none',
                background: '#7d5ff5',
                color: 'white',
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsDrawerOpen(false);
              }}
            >
              Close
            </button>
            {/* <div>next</div> */}
          </div>
          <div className="day-card">
            <div
              className="day-details"
              style={{
                border: '3px solid lightgrey',
                marginBottom: '1rem',
              }}
            >
              <Typography
                variant="h6"
                style={{
                  fontFamily: 'DMSansBold',
                  fontWeight: 700,
                  marginBottom: '1.2rem',
                }}
              >
                {day} - {title}
              </Typography>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor: '#f0f4f8' }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontFamily: 'DMSansRegular',
                      fontWeight: 600,
                      fontSize: '1rem',
                    }}
                  >
                    Lesson Overview
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="lessonoverview-details">
                  <LessonOverviewButton
                    overview={overviewState}
                    dayNumber={`day${dayNumber}`}
                    unitDetails={unitDetails}
                    unitName={unitName}
                    day={day}
                    title={title}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  style={{ backgroundColor: '#f0f4f8' }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontFamily: 'DMSansRegular',
                      fontWeight: 600,
                      fontSize: '1rem',
                    }}
                  >
                    Student Objectives
                  </Typography>
                </AccordionSummary>
                <div className="studentobjectives-container">
                  <AccordionDetails>
                    <StudentObjectivesButton
                      overview={overviewState}
                      dayNumber={`day${dayNumber}`}
                      unitDetails={unitDetails}
                    />
                  </AccordionDetails>
                </div>
              </Accordion>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  style={{ backgroundColor: '#f0f4f8' }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontFamily: 'DMSansRegular',
                      fontWeight: 600,
                      fontSize: '1rem',
                    }}
                  >
                    Essential Questions
                  </Typography>
                </AccordionSummary>
                <div className="studentobjectives-container">
                  <AccordionDetails>
                    <EssentialQuestionsButton
                      overview={overviewState}
                      dayNumber={`day${dayNumber}`}
                      unitDetails={unitDetails}
                    />
                  </AccordionDetails>
                </div>
              </Accordion>
            </div>
            <div
              className="day-resources"
              style={{
                border: '3px solid lightgrey',
                marginBottom: '1rem',
              }}
            >
              <Typography variant="h6" style={{ fontFamily: 'DMSansRegular', fontWeight: 700 }}>
                Lesson Resources
              </Typography>
              <br />
              <LessonPlanButton overview={overviewState} dayNumber={`day${dayNumber}`} unitDetails={unitDetails} />
              <EducationalHandoutButton
                overview={overviewState}
                dayNumber={`day${dayNumber}`}
                unitDetails={unitDetails}
              />
              <ContextBuilderButton overview={overviewState} dayNumber={`day${dayNumber}`} unitDetails={unitDetails} />
              <div className="video-resources">
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: 'DMSansBold',
                    fontWeight: 700,
                    paddingBottom: '1.5rem',
                  }}
                >
                  AI Video Recommendations
                </Typography>
                <VideoList lessonTopic={title} />
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
      <Card
        sx={{
          width: '100%',
          maxWidth: '100%',
          border: '2px solid #cdc1fb',
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15)',
          borderRadius: '10px',
          height: '100%',
          '&:hover': {
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
            border: '2px solid #a665ff',
          },
        }}
      >
        <CardContent>
          <div className="dayCard">
            <div>
              <article>
                <div
                  className="emoji"
                  style={{
                    fontSize: '.9rem',
                    fontFamily: 'DMSansMedium',
                  }}
                >
                  {' '}
                  <IoTimerOutline className="button-icon" />
                  {day}
                </div>
              </article>
              <Typography
                variant="h5"
                component="div"
                className="toolTitle"
                sx={{
                  fontSize: '1.1rem',
                  fontFamily: 'DMSansBold',
                }}
              >
                {title}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: overview
                    .replace(/\n/g, '<p style="margin-top: 0.5em; margin-bottom: 0.5em;">')
                    .substring(0, 180),
                }}
                sx={{
                  fontFamily: 'DMSansRegular',
                  fontSize: '.9rem',
                }}
              ></Typography>
            </div>
            <div className="actionButtons" style={{ marginTop: '1rem' }}>
              <button className="btn btn-block drawerbtn" onClick={() => setIsDrawerOpen(true)}>
                {' '}
                <IoEyeSharp className="button-icon" />
                View Resources
              </button>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default Tool;
